import { HospitalFilters } from './../models/hospital-filters';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hospitalFilters: HospitalFilters;
  
  constructor(
    public router: Router,
  ) { }

  login(event: Event) {
    
      this.hospitalFilters = {
        q: 'test',
        zip: '77030',
        withinMiles: 10000,
        pageNum: 1,
     //   pageSize: 10,
        orderBy: 'distance',
        showAll: 'false',
      };
      
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    }).then(() => {
      window.location.reload();
    });
    
  }
  ngOnInit(): void {
  
  }

}
