import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  Event,
  NavigationStart,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormBuilder } from '@angular/forms';

import { CommonServiceService } from './../../common-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('externalLogin') externalLogin;

  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  
  closeNav() {
    console.log("click")
    document.getElementById("html").classList.remove("menu-opened");
    document.getElementById("main-menu-wrapper").style.transform = "translateX(-260px);";
  
  }
  
  Issearchpage:boolean=false;
  auth: boolean = false;
  isPatient: boolean = false;
  page;
  headerTop: boolean = false;
  base;
  constructor(
    @Inject(DOCUMENT) private document,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private activeRoute: ActivatedRoute,
    public commonService: CommonServiceService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      var res = event.url.split("/");
      this.base = res[1];
      this.page = res[2];
      if(this.base=="" || this.base=="home"){
        //this.router.navigate(['/home']);
        document.getElementById('home').style.color= "#EA2915";
      }
      else{
        document.getElementById('home').style.color= "#000000";
      }

        if ( event.url == '/home-slider-one') {         
          this.headerTop = true;
        } else {
          this.headerTop = false;
        }
      }
    });
    // this.commonService.message.subscribe((res) => {
    //   if (res === 'patientLogin') {
    //     this.auth = true;
    //     // this.isPatient = true;
    //   }
    //   if (res === 'doctorLogin') {
    //     this.auth = true;
    //     // this.isPatient = false;
    //   }
    //   if (res === 'logout') {
    //     this.auth = false;
    //     this.isPatient = false;
    //   }
    // });
  }

  public href: string = "";

  login() {
    this.externalLogin.nativeElement.submit();
  }
  logout(){
    this.Issearchpage=false;
    this.router.navigate(['/']);
    //location.reload();
  }
  ngOnInit(): void {
    if(window.location.pathname=="/search"){
      this.Issearchpage=true;
    }

    // if (localStorage.getItem('auth') === 'true') {
    //   this.auth = true;
    //   this.isPatient =
    //     localStorage.getItem('patient') === 'true' ? true : false;
    // }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  // mapGrid() {
  //   this.router.navigate(['/map-grid']);
  // }

  // mapList() {
  //   this.router.navigate(['/map-list']);
  // }

  addStyle() {
    if (document.getElementById('submenu').style.display == 'block') {
      document.getElementById('submenu').style.display = 'none';
    } else {
      document.getElementById('submenu').style.display = 'block';
    }
  }

  // doctor(name) {
  //   this.page = name;
  //   this.router.navigate(['/doctor/dashboard']);
  // }

  // logout() {
  //   localStorage.clear();
  //   this.auth = false;
  //   this.isPatient = false;
  //   this.router.navigate(['/login']);
  // }

  home() {
    this.commonService.nextmessage('main');
    this.router.navigateByUrl('/').then(() => {
      this.router.navigate(['/']);
    });
  }

  // navigate(name) {
  //   this.page = name;
  //   if (name === 'Admin') {
  //     this.router.navigate(['/admin']);
  //     this.commonService.nextmessage('admin');
  //   } else if(name === 'Pharmacy Admin'){
  //     this.router.navigate(['/pharmacy-admin']);
  //     this.commonService.nextmessage('pharmacy-admin');
  //   }
  // }
}
