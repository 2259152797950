import { Payers } from '../models/payers';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayersService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }
  createPayers(data: Payers): void {
    throw new Error("Method not IMplemented")
  }
  public payersList: Payers[];

  getPayerDetails(q: string):  Observable<any> {
    let url=q!=""?"api/payers?payers="+q:"api/payers";
    return this.http.get(this.getBaseUrl() + url);
  }

  // getPayerDetails(q:string): any {
  //   let url=q!=""?"api/payers?payers="+q:"api/payers";
  //   return this.axios
  //     .get(url).catch((error) => {
  //       this._handleError(error);
  //     });
  //   }

 

}
