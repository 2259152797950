import { Component, OnInit } from '@angular/core';
import { ScrollService } from "./../services/scroll.service";

@Component({
  selector: 'app-whymatters',
  templateUrl: './whymatters.component.html',
  styleUrls: ['./whymatters.component.css']
})
export class WhymattersComponent implements OnInit {

  constructor(private ScrollService: ScrollService) { }

  ngOnInit(): void {
    this.ScrollService.setScrollTop();
  }

}
