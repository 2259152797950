import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';

@Pipe({
 name: "orderBy"
})

@Injectable({
  providedIn: 'root'
})
export class OrderbyService implements PipeTransform {

  constructor() { }
  transform(array: any, sortBy: string, order ): any[] {
    const sortOrder = order ? order : 'asc'; // setting default ascending order
    console.log("orderby called")
     return orderBy(array, [sortBy], [sortOrder]);
     }

    GetSortOrder(prop) {    
      return function(a, b) {    
          if (a[prop] > b[prop]) {    
              return 1;    
          } else if (a[prop] < b[prop]) {    
              return -1;    
          }    
          return 0;    
      }    
  }  
}
