import { HospitalsVM } from './../models/hospitals-vm';
import { Observable, of } from "rxjs";
import { HospitalPricingVM } from "./../models/hospital-pricing-vm";
import { BaseService } from "./base.service";
import { Inject, Injectable } from "@angular/core";
import { HospitalFilters } from "../models/hospital-filters";
import { HospitalPricingVM1 } from "../models/hospitals";
import { HospitalListFilters } from '../models/hospital-list-filters';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';


@Injectable({
  providedIn: "root",
})
export class HospitalsService extends BaseService {

  
  constructor(private http: HttpClient) {
    super();
  }

  createPayers(data: HospitalPricingVM): void {
    throw new Error("Method not IMplemented");
  }
  // getHospitalDetails(filters: HospitalFilters): any {
  //   console.log(filters.q, "q");
  //   if(filters.q==""){
  //     return "Search hospitals here";
  //   }
  //   else{
  //   return this.axios
  //     .get("api/health-services?" + this.getParams(filters))
  //     .catch((error) => {
  //       this._handleError(error);
  //     });
  //   }
  // }

  getHospitalDetails(filters: HospitalFilters):  Observable<any> {
    console.log(filters,"hospital service filters");
    if(filters.q==null){
          return of('Search hospitals here');; //JSON.parse("Search hospitals here");
    }
    else{
      let url = "api/health-services?" + this.getParams(filters);
      return this.http.get(this.getBaseUrl() + url)
      .pipe(
        timeout(240000)
      );
    }
  }
  
  getAllHospitalName(q: string):  Observable<any> {
    let url = q != "" ? "api/allhospital?hospitalsname=" + q : "api/allhospital";
    console.log(this.getBaseUrl() + url);
    return this.http.get(this.getBaseUrl() + url);
  }

   //getAllHospitalName(q: string): any {

   // http.get<HospitalListFilters>(this.baseURL + 'weatherforecast').subscribe(result => {
   //  return result;
   // }, error => console.error(error));


   // let url=q!=""?"api/allhospital?hospitalsname="+q:"api/allhospital";
   // return this.axios
   //   .get(url).catch((error) => {
   //     this._handleError(error);
   //   });
   // }

    //getHospitalList(filters: HospitalListFilters): any {
    //let url="api/hospitals?"+this.getParams(filters);
    //return this.axios
    //  .get(url).catch((error) => {
    //    this._handleError(error);
    //  });
    //}

  public hospitallist: HospitalPricingVM1[];
  gethospitallist1() {
    this.hospitallist = [
      {
        name: "Medlife Medical",
        ratings: "3.0",
        avgrating: 10,
        phone: "320-795-8815",
        address_link: "96+Red+Hawk+Road+Cyrus,+MN+56323",
        address: "96 Red Hawk Road Cyrus, MN 56323",
        treatment: "Brain lymphoma",
        time: "08.00",
        grossprice: 300,
        selfpayprice: 120,
        minprice: 120,
        maxprice: 300,
        medicareprice: 80,
        miles: 23,
      },
      {
        name: "PharmaMed Medica",
        ratings: "4.0",
        avgrating: 17,
        phone: "913-631-2538",
        address_link: "3830+Poe+Lane+Kansas+City,+KS+66216",
        address: "3830 Poe Lane Kansas City, KS 66216",
        treatment: "Brain tumor",
        time: "08.00",
        grossprice: 300,
        selfpayprice: 120,
        minprice: 120,
        maxprice: 300,
        medicareprice: 80,
        miles: 15,
      },
      {
        name: "The Pill Club Medical",
        ratings: "3.0",
        avgrating: 20,
        phone: "816-270-2336",
        address_link: "3849+Nutter+Street+Ferrelview,+MO+64163",
        address: "3849 Nutter Street Ferrelview, MO 64163",
        treatment: "Cord pain",
        time: "08.00",
        grossprice: 300,
        selfpayprice: 120,
        minprice: 120,
        maxprice: 300,
        medicareprice: 80,
        miles: 30,
      },
      {
        name: "Cleveland Medical Medical",
        ratings: "3.0",
        avgrating: 10,
        phone: "320-795-8815",
        address_link: "96+Red+Hawk+Road+Cyrus,+MN+56323",
        address: "96 Red Hawk Road Cyrus, MN 56323",
        treatment: "Surgeon, Surgical Equipment Dealer",
        time: "08.00",
        grossprice: 300,
        selfpayprice: 120,
        minprice: 120,
        maxprice: 300,
        medicareprice: 80,
        miles: 8,
      },
      {
        name: "Mayo Medical",
        ratings: "3.0",
        avgrating: 10,
        phone: "320-795-8815",
        address_link: "96+Red+Hawk+Road+Cyrus,+MN+56323",
        address: "96 Red Hawk Road Cyrus, MN 56323",
        treatment: "Surgeon, Surgical Equipment Dealer",
        time: "08.00",
        grossprice: 300,
        selfpayprice: 120,
        minprice: 120,
        maxprice: 300,
        medicareprice: 80,
        miles: 19,
      },
    ];
    return this.hospitallist;
  }

  // getHospitals(): any {
  //   // return this.axios.get<HospitalPricingVM[]>("api/Hospitals/G0127");
  // }

  // return this.axios.get('api/Hospitals/G0127')
  //   .then(function (response) {
  //     return response;
  //   });
}
