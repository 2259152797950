import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Contactus } from '../models/contactus';
import { ContactService } from '../services/contact.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  doctorDetails;
  doctorId;
  recaptchaSiteKey:any;
  firstName:string;
  lastName:string;
  email:string;
  phone:string;
  Message:string;
  appointments: any = [];
  patients: any = [];
  FormData: FormGroup;
  Contactus:Contactus;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private builder: FormBuilder,
    private contactService: ContactService,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.FormData = this.builder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)]),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      Message: new FormControl('', Validators.required),
      recaptcha: ['', Validators.required]
    })

     this.recaptchaSiteKey='6Ldv4YUcAAAAAD4eYQgI4l9AyC-LIyfD7JKA_JOy';
  }

  // handleError(event){
  //  // console.log(event);
  // }
  // handleLoad(event){
  //   // console.log(event);
  //  }
  
  onSubmit() {
    this.Contactus={
      email:this.email,
      firstName:this.firstName,
      lastName:this.lastName,
      phone:this.phone,
      message:this.Message
    };
    
    (<HTMLInputElement> document.getElementById("btnsubmit")).disabled = true;
    this.contactService.SendcontactUs(this.Contactus)
    .subscribe(result => {
      (<HTMLInputElement> document.getElementById("btnsubmit")).disabled = false;
      console.log("Email sent!");
      this.toastr.success("Submitted");
      this.captchaElem.resetCaptcha();
      this.FormData.reset(); 
    },
    err => {
      (<HTMLInputElement> document.getElementById("btnsubmit")).disabled = false;
      this.toastr.error("Error");},
    );
  }

}
