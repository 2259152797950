import { BaseService } from './base.service';
import { Injectable } from '@angular/core';


export interface Pricing_range {
  id:number;
  price_range:string;
  isactive:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PricingService extends BaseService{

  constructor() { super()}

  createprices(data: Pricing_range): void {
    throw new Error("Method not IMplemented")
  }
  public priceList: Pricing_range[];
  getpricelist() {
    this.priceList = [{
      id:1,
      price_range:'$15-$50',
      isactive:false
    },
    {
      id:2,
      price_range:'$51-$100',
      isactive:true
    },
    {
      id:3,
      price_range:'$101-$300',
      isactive:true
    },
    {
      id:4,
      price_range:'greater than $300',
      isactive:false
    }    ];
    return this.priceList
  }
}
