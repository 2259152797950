import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { Injectable, NgZone } from '@angular/core';
import { LocationVM } from '../models/location';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService {
latitude:number;
longitude:number;
geocoder:any;
location:LocationVM;
constructor(public mapsApiLoader: MapsAPILoader,
  private zone: NgZone,
  private wrapper: GoogleMapsAPIWrapper) {
    super();
this.mapsApiLoader = mapsApiLoader;
this.zone = zone;
this.wrapper = wrapper;
this.mapsApiLoader.load().then(() => {
this.geocoder = new google.maps.Geocoder;
});
}
  
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        
       // this.findAddressByCoordinates(position.coords.latitude,position.coords.longitude);
      });

    }
    const latlang={lat:this.latitude,lang:this.longitude}
    return latlang;
  }
  private findAddressByCoordinates(latitude,longitude) {
    
    this.geocoder.geocode({
      'location': {
        lat: latitude,
        lng: longitude
      }
    }, (results, status) => {
      console.log(results, status);
      this.decomposeAddressComponents(results);
    });
  }
  private decomposeAddressComponents(addressArray) {
    if (addressArray.length == 0) return false;
    let address = addressArray[0].address_components;
   //this.location=new Location();
    for(let element of address) {
      if (element.length == 0 && !element['types']) continue

      if (element['types'].indexOf('street_number') > -1) {
        this.location.address_level_1 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('route') > -1) {
        this.location.address_level_1 += ', ' + element['long_name'];
        continue;
      }
      if (element['types'].indexOf('locality') > -1) {
        this.location.address_level_2 = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('administrative_area_level_1') > -1) {
        this.location.address_state = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('country') > -1) {
        this.location.address_country = element['long_name'];
        continue;
      }
      if (element['types'].indexOf('postal_code') > -1) {
        this.location.address_zip = element['long_name'];
        continue;
      }
    }
    console.log(location);
  }
}
