import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HospitalFilters } from '../models/hospital-filters';
import { map } from 'jquery';

@Injectable()
export class BaseService {
  protected readonly axios: AxiosInstance;
  private config: AxiosRequestConfig;
  constructor() {
    this.axios = Axios.create({ baseURL: this.getBaseUrl() });
    this.axios.defaults.headers['Content-Type'] = 'application/json';
    this.axios.defaults.headers['Access-Control-Allow-Credentials'] = 'true';
    this.axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
    this.axios.defaults.headers['Access-Control-Allow-Methods'] =
      'POST, GET, DELETE';
    this.axios.defaults.headers['Access-Control-Max-Age'] = '3600';
    this.axios.defaults.headers['Access-Control-Allow-Headers'] =
      'Access-Control-Allow-Headers: Origin, X-Requested-With, Content-Type, Accept, Client-Offset';
    //this.axios.interceptors.request.use(function (config) {
    //  config.headers.Authorization = `Bearer ${this.authorize.getAccessToken()}`;
    //  return config;
    //}, null);

    // console.log(this.axios, this.getBaseUrl());
    this._initializeResponseInterceptor();
  }
  private _initializeResponseInterceptor = () => {
    this.axios.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };
  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);

  protected getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
  }

  protected getParams(object: HospitalFilters) {
    let params = new URLSearchParams();
    map(object, (value, key) => {
      if (Array.isArray(object[key])) {
        map(value, (item) => params.append(key, item.toString()));
      } else {
        params.append(key, value.toString());
      }
    });
    return params.toString();
  }
}
