import { ContactSupportComponent } from './contact-support/contact-support.component';
// import { LoginComponent } from './login/login.component';

import { PolicyComponent } from './policy/policy.component';
import { HomeComponent } from './home/home.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { SearcheddataComponent } from './searcheddata/searcheddata.component';
import { HospitalmissinglistComponent } from './hospitalmissinglist/hospitalmissinglist.component';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApplicationPaths } from 'src/api-authorization/api-authorization.constants';
import { LoginComponent } from 'src/api-authorization/login/login.component';
import { LogoutComponent } from 'src/api-authorization/logout/logout.component';
import { WhymattersComponent } from './whymatters/whymatters.component';
import { DetailsComponent } from './details/details.component';
import { TermsandconditionComponent } from './termsandcondition/termsandcondition.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
 
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'search',data: { reuse: true },
    component: SearcheddataComponent, canActivate: [AuthorizeGuard]
  },
  { path: 'whymatters', component: WhymattersComponent },
  { path: 'details', component: DetailsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'contact-support', component: ContactSupportComponent, canActivate: [AuthorizeGuard]},
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'termsandcondition', component: TermsandconditionComponent },
  { path: ApplicationPaths.Login, component: LoginComponent},
  // {path:'login-app', component:LoginComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {path:'policy',component:PolicyComponent},
  {
    path:'hospitalmissinglist',component:HospitalmissinglistComponent
  },
  { path: ApplicationPaths.LogOut, component: LogoutComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
