import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-hospitalmissinglist',
  templateUrl: './hospitalmissinglist.component.html',
  styleUrls: ['./hospitalmissinglist.component.css'],
  providers:[NgbRatingConfig]
})
export class HospitalmissinglistComponent implements OnInit {
  lat: number = 51.678418;
  lng: number = 7.809007;
  minValue: number = 0;
  maxValue: number = 1000000;

  options: Options = {
    floor: 0,
    ceil: 1000000,
    // step: 100000,
    // showTicks: true
    };
  constructor(
    
    private modalService: NgbModal,
    config: NgbRatingConfig
  ) {

    config.max = 5;
  }

  ngOnInit(): void {
  }

}
