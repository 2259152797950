import { DistanceService } from './distance.service';
import { Services } from './../models/services';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends BaseService {

  constructor() { super(); }

  createCategories(data: Services): void {
    throw new Error("Method not IMplemented")
  }
  public categoryList: Services[];
  getCategorylist() {
    this.categoryList = [{
      id: 1,
      serviceName: "Evaluation & Management (99201 – 99499)",
      commonName: "Evaluation & Management Services",
      metaDataTags: "md1",
      serviceCode:"md1",
      CPTCode: "md1",
      revId:1,
      DRG: "md1",
      isactive: true,
    }, {
      id: 2,
      serviceName: "Anesthesia (01000 – 01999)",
      commonName: "Anesthesia Services",
      metaDataTags: "md2",
      serviceCode:"md1",
      CPTCode: "md1",
      revId:1,
      DRG: "md1",
      isactive: false,
    },
    {
      id: 3,
      serviceName: "Surgery (10021 – 69990) ",
      commonName: "Surgery– further broken into body area or system within this code range",
      metaDataTags: "md3",
      serviceCode:"md1",
      CPTCode: "md1",
      revId:1,
      DRG: "md1",
      isactive: false,
    },
    {
      id: 4,
      serviceName: "Radiology (70010 – 79999)",
      commonName: "Radiology Services",
      metaDataTags: "md1",
      serviceCode:"md1",
      CPTCode: "md1",
      revId:1,
      DRG: "md1",
      isactive: false,
    },
    {
      id: 5,
      serviceName: "Pathology and Laboratory (80047 – 89398)",
      commonName: "Pathology and Laboratory Services",
      metaDataTags: "md5",
      serviceCode:"md1",
      CPTCode: "md1",
      revId:1,
      DRG: "md1",
      isactive: false,
    },
    {
      id: 6,
      serviceName: "Medical and Procedures (90281 – 99607)",
      commonName: "Medical Services and Procedures",
      metaDataTags: "md6",
      serviceCode:"md1",
      CPTCode: "md1",
      revId:1,
      DRG: "md1",
      isactive: false,
    },
    ];
    return this.categoryList
  }


}
