import { BaseService } from './base.service';
import { Injectable } from '@angular/core';


export interface Distance_range {
  id: number;
  distance_range: string;
  isactive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DistanceService extends BaseService {

  constructor() { super() }

  createdistances(data: Distance_range): void {
    throw new Error("Method not IMplemented")
  }
  public distanceList: Distance_range[];
  getdistancelist() {
    this.distanceList = [{
      id: 1,
      distance_range: '5-10 Miles',
      isactive: false
    },
    {
      id: 2,
      distance_range: '11-20 Miles',
      isactive: true
    },
    {
      id: 3,
      distance_range: '21-30 Miles',
      isactive: true
    },
    {
      id: 4,
      distance_range: '31-50 Miles',
      isactive: false
    },
    {
      id: 4,
      distance_range: 'greater than 50Miles',
      isactive: false
    }
  ];
    return this.distanceList
  }
}
