import { Injectable } from '@angular/core';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// import { ChangeDetectorRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    // private changeDetect: ChangeDetectorRef
    
  ) {}

  setScrollTop() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // Change height:100% into auto
      document.body.style.height="auto";
      // Successfully scroll back to top
      document.body.scrollTop=0;
      // Remove javascript added styles
      document.body.style.height='';

    });

  }
    
    // if (isPlatformBrowser(this.platformId)) {
      
    //   this.router.events.subscribe((event: NavigationEnd) => {
    //     window.scrollTo(0, 0);
    //     console.log("scroll")
    //   });
    // }
  // }
}
