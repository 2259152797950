import { SearcheddataComponent } from './searcheddata/searcheddata.component';
import { HomeComponent } from './home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import {AgmCoreModule,GoogleMapsAPIWrapper } from '@agm/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatInputModule } from '@angular/material/input';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxPaginationModule } from 'ngx-pagination';
// import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from "ngx-spinner";
import { PolicyComponent } from './policy/policy.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HospitalmissinglistComponent } from './hospitalmissinglist/hospitalmissinglist.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { StickySidebarModule } from 'raven-sticky-sidebar';
import {MatRadioModule} from '@angular/material/radio';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LoginComponent } from './login/login.component';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { OrderbyService } from './services/orderby.service';
import { WhymattersComponent } from './whymatters/whymatters.component';
import { DetailsComponent } from './details/details.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { TermsandconditionComponent } from './termsandcondition/termsandcondition.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DataService } from './data.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxMaskModule, IConfig } from 'ngx-mask'
//import { Ng2GoogleRecaptchaModule } from 'ng2-google-recaptcha';
// import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    OrderbyService,
    HomeComponent, 
    HeaderComponent,
    SearcheddataComponent, 
    FooterComponent,
    WhymattersComponent,
    DetailsComponent, 
    AboutUsComponent, 
    ContactUsComponent, 
    HowItWorksComponent,
    TermsandconditionComponent, PolicyComponent, HospitalmissinglistComponent, LoginComponent,
    ForgotPasswordComponent,
    ContactSupportComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    
  ],
  imports: [
    MatRadioModule,
    UiSwitchModule,
    StickySidebarModule,
    BrowserModule,
    NgxPaginationModule,
    Ng5SliderModule,
    AutocompleteLibModule,
    MatInputModule,
    NgSelectModule,
    AngularMultiSelectModule,
    TooltipModule,
    // SelectAutocompleteModule,
    NgxSpinnerModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    NgxCaptchaModule,
    ToastrModule.forRoot({positionClass: 'toast-top-center'}),
   // HttpClientInMemoryWebApiModule.forRoot(DataService),
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    NgbModule,
    Daterangepicker,
    ApiAuthorizationModule,
   // Ng2GoogleRecaptchaModule,
   NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({  
      apiKey: 'AIzaSyAo2xqMws27IL9WkxmnEEgbT02ZAji2Huw' 
    }),
  ],
  providers: [GoogleMapsAPIWrapper,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
   
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
