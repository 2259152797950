import { HospitalFilters } from './../models/hospital-filters';
import { HospitalListFilters } from './../models/hospital-list-filters';
import { HospitalMissingData } from './../models/hospital-missing-data';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
  EventEmitter,
  Pipe,
  PipeTransform,
  ViewEncapsulation,
} from '@angular/core';
import { HospitalPricingVM } from './../models/hospital-pricing-vm';
import { OrderbyService } from './../services/orderby.service';
import { HospitalsService } from './../services/hospitals.service';
import { DistanceService } from './../services/distance.service';
import { PricingService } from './../services/pricing.service';
import { Services } from './../models/services';
import { CategoriesService } from './../services/categories.service';
import { Payers } from './../models/payers';
import { PayersService } from '../services/payers.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Options, LabelType } from 'ng5-slider';
import { ScrollService } from './../services/scroll.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import {
  MatRadioButton,
  MatRadioChange,
  MatRadioModule,
  MAT_RADIO_DEFAULT_OPTIONS,
} from '@angular/material/radio';
import { NonNullAssert } from '@angular/compiler';
// import 'jquery-ui';

@Component({
  selector: 'app-searcheddata',
  templateUrl: './searcheddata.component.html',
  styleUrls: ['./searcheddata.component.css'],
  providers: [
    PayersService,
    CategoriesService,
    PricingService,
    DistanceService,
    HospitalsService,
    OrderbyService,
    NgbRatingConfig,
  ],
  encapsulation: ViewEncapsulation.None,
})
@(Output()({ change: new EventEmitter<MatRadioChange>() }))
@Pipe({
  name: 'noComma',
})
export class SearcheddataComponent
  implements OnInit, AfterViewInit, PipeTransform {
  // @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;

  public payerlist: Payers[];
  public categorylist: Services[];
  public priceList: any[];
  public distanceList: any[];
  public hospitalList: HospitalPricingVM[];
  hospitalFilters: HospitalFilters;
  hospitalListFilters: HospitalListFilters;
  route: any;
  locationname: string;
  treatmentname: string;
  miles: number;
  config: any;
  closeResult: string;
  keyword = 'name';
  dropdownList = [];
  dropdownList_hospital = [];
  selectedItems = [];
  dropdownSettings = {};
  pricelist = [];
  distancelist = [];
  categoriesList = [];
  hospitalslist = [];
  hospitalsname=[];
  // missingHospitalList=[];
  totalHospitalData: number;
  selectedOptions = [];
  selectedOptions_hospital = [];
  treatmentname_bread: string;
  locationname_bread: string;
  selectedDropdown = 'distance';
  radiobuttonvalue = 'false';
  search_length=0;
  hospitalsearch_length=0;
  minValue: number = 0;
  maxValue: number = 1000000;

  options: Options = {
    floor: 0,
    ceil: 1000000,
    // step: 100000,
    // showTicks: true
  };
  
  selected = this.selectedOptions;
  showError = false;
  errorMessage = '';
  starselected = 0;
  prev_starselected = 0;
  hovered = 0;
  show_title_popup = false;
  dropdownSettings_hospital: {};
  selected_hospital = this.selectedOptions_hospital;
  hospitallistfilter = [];
  // totalmissingHospitalData=0;
  miles_modal: number;
  valueChange = false;
  message:string;
  FormData: FormGroup;
  constructor(
    private payer: PayersService,
    private orderby: OrderbyService,
    private activatedRoute: ActivatedRoute,
    private ScrollService: ScrollService,
    private category: CategoriesService,
    private price: PricingService,
    private distance: DistanceService,
    private hospitalService: HospitalsService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private builder: FormBuilder,
    config: NgbRatingConfig
  ) {
    config.max = 5;
  }

  // for modal popup
  // openScrollableContent(longContent) {
  //   this.modalService.open(longContent, { scrollable: true,centered: true  });
  // }

  locationHandler() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationname =
          parseFloat(position.coords.latitude.toString()).toFixed(2) +
          ', ' +
          parseFloat(position.coords.longitude.toString()).toFixed(2);
      });
    }
  }

  onValueChange(value: boolean) {
    this.valueChange = value;
    console.log(value,"onvaluechange")
    this.radiobuttonvalue = String(value);
    console.log("radio",this.radiobuttonvalue)
    this.hospitalFilters.showAll = this.radiobuttonvalue;

    this.hospitalFilters.pageNum = 1;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
  }

  onChangeRadio(mrChange: MatRadioChange) {
    this.radiobuttonvalue = mrChange.value;
    this.hospitalFilters.showAll = this.radiobuttonvalue;
    let mrButton: MatRadioButton = mrChange.source;
    console.log(mrButton.checked);

    this.hospitalFilters.pageNum = 1;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
  }

  transform(value: any) {
    if (value !== undefined && value !== null) {
      // here we just remove the commas from value
      return value.toString().replace(/,/g, '');
    } else {
      return '';
    }
  }

  onCategoryCheckboxChange(id: number, event: any) {}

  onPriceCheckboxChange(id: number, event: any) {}
  onDistanceCheckboxChange(id: number, event: any) {}

  ratingchangedHandler() {
    this.hospitalFilters.rating = this.starselected;
    this.hospitalFilters.pageNum = 1;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
  }
  counter(n: string): any[] {
    return Array(parseInt(n));
  }

  remcounter(n: string): any[] {
    // rem=5-parseInt(n);
    return Array(5 - parseInt(n));
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.hospitalFilters.pageNum = this.config.currentPage;
    //this.hospitalFilters.pageSize = this.config.itemsPerPage;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
    document.title ='Evaluprice';
  }
  hospitalFilter(event) {
    console.log(event.target.attributes['ng-reflect-id'].value,"hospital filter");
    let id=event.target.attributes['ng-reflect-id'].value
    var breakloop=true;
    this.hospitalList.forEach(element => {
      if(breakloop){
        if(element.id==id){
          this.selected_hospital=[element.hospitalName];
          this.hospitalFilters.hospitals = this.selected_hospital;
          this.hospitalFilters.pageNum = 1;
          this.router.navigate(['/search'], {
            queryParams: this.hospitalFilters,
          });
          this.getHospitals();
          console.log("match condition");
          breakloop=false;
        }
      }
    });
  }
  // async getPayers() {
  //   const result = await this.payer.getPayerDetails('');

  //   this.payerlist = result;

  //   let dropdownList_cap = this.payerlist;

  //   this.dropdownList = dropdownList_cap.map((s) => ({
  //     itemName: s.itemName.toUpperCase(),
  //     id: s.id,
  //   }));

  //   console.log('this.dropdownlist', this.dropdownList);

  //   let payers = this.activatedRoute.snapshot.queryParamMap.getAll('payers');
  //   console.log('payer on init', payers);
  //   if (payers.length != 0) {
  //     this.selected = this.dropdownList.filter((p) =>
  //       payers.includes(p.itemName)
  //     );
  //     console.log(this.selected, 'payers selection');
  //     this.hospitalFilters.payers = this.selected.map((s) => s.itemName);
  //   }
  //   this.router.navigate(['/search'], {
  //     queryParams: this.hospitalFilters,
  //   });
  // }

  addPayer() {
    console.log('key up event');
  }

  //async getHospitalList() {
   
  //  const result = await this.hospitalService.getHospitalList(
  //    this.hospitalFilters
  //  );
  //  if(typeof(result) != 'undefined'){
  //  this.hospitallistfilter = result;
   
  //  let dropdownList_hos_cap = this.hospitallistfilter;
    
  //  this.dropdownList_hospital = dropdownList_hos_cap.map((s) => ({
  //    itemName: s.itemName.toUpperCase(),
  //    id: s.id,
  //  }));
    

  //  let hospitals = this.activatedRoute.snapshot.queryParamMap.getAll(
  //    'hospitals'
  //  );

  //  if (hospitals.length != 0) {
  //    this.selected_hospital = this.dropdownList_hospital.filter((p) =>
  //      hospitals.includes(p.itemName)
  //    );
  //    this.hospitalFilters.hospitals = this.selected_hospital.map(
  //      (s) => s.itemName
  //    );
  //  }
  //  // this.router.navigate(['/search'], {
  //  //   queryParams: this.hospitalFilters,
  //  // });
  //}
  //}

  async getHospitals() {
    this.spinner.show();
    
    this.hospitalService.getHospitalDetails(
      this.hospitalFilters
    ).subscribe(
      (response) => {  
        this.spinner.hide();           
        const result = response;
        console.log(result);
        if(result=="Search hospitals here"){
          this.message=result;
          this.miles=0;
          this.treatmentname='';
          this.locationname='';
          this.treatmentname_bread = this.treatmentname;
          this.locationname_bread = this.locationname;
          this.miles_modal = this.miles;
        }
        else{
        this.message=null;
        this.hospitalList = result.results;
        const summary = result.summary;
    
        this.treatmentname_bread = this.treatmentname;
        this.locationname_bread = this.locationname;
        this.miles_modal = this.miles;
        this.totalHospitalData = summary.count;
       // this.options={ceil:parseInt(summary.max+100)};
        //this.options.floor=summary.;
        //this.minValue=summary.min;
        //this.maxValue=summary.max;
        this.config = {
          itemsPerPage: 10,
          // parseInt(
          //   this.activatedRoute.snapshot.queryParamMap.get('pageSize')
          // ),
          currentPage: parseInt(
            this.activatedRoute.snapshot.queryParamMap.get('pageNum')
          ),
          totalItems: summary.count,
        };
      }

      },
      (error) => {
        this.spinner.hide();
        
      }
    );
  }

  //sorting of data
  selectChangeHandler(event: any) {
    //update the ui
    this.selectedDropdown = event.target.value;
    this.hospitalFilters.orderBy = this.selectedDropdown;
    this.hospitalFilters.pageNum = 1;

    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();

    // this.hospitalList.sort(this.orderby.GetSortOrder(this.selectedDropdown));
  }

  getSelectedOptions(selected) {
    this.selected = selected;
  }

  async searchevent(value:any){
    this.search_length=value.term.length;
    if(this.search_length>=2){
      this.payer.getPayerDetails(value.term).subscribe(
        (response) => {             
          this.payerlist = response;
          let dropdownList_cap = this.payerlist;
          this.dropdownList = dropdownList_cap.map((s) => ({
            name: s.name.toUpperCase()
          }));
        });
      }
      else{
        this.hospitalsname = [];
      }
  }
  
  PayerChangeHandler() {
    // this.hospitalFilters.payers = this.selected.map((s) => s.itemName);
    this.hospitalFilters.payers = this.selected
    this.hospitalFilters.pageNum = 1;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    $('.ng-input').find('input:text').val('');
    this.getHospitals();
  }

  onItemSelect(item: any) {
    console.log($('.ng-input').find('input:text').val(''),"inside select"); 
   // $('.ng-input').find('input:text').val('');
    this.PayerChangeHandler();
  }
  OnItemDeSelect(item: any) {
    this.PayerChangeHandler();
  }
  onSelectAll(items: any) {
  //  $('.ng-input').find('input:text').val('');
    this.PayerChangeHandler();
  }
  onDeSelectAll(items: any) {
    this.PayerChangeHandler();
  }

  async Hospitalsearchevent(value:any){
    console.log('searched value is: ', value.term.length);
    this.hospitalsearch_length=value.term.length;
    if(this.hospitalsearch_length>=2){
    this.hospitalService.getAllHospitalName(value.term).subscribe(
      (response) => {             
        this.hospitalsname = response;
        let dropdownList_cap = this.hospitalsname;
        console.log(this.hospitalsname,"hospital list");
        this.dropdownList_hospital = dropdownList_cap.map((s) => ({
          name: s.name.toUpperCase()
        })); 
      });
    }
    else{
      this.hospitalsname = [];
    }
  }
  HospitalNameChangeHandler() {
    // this.hospitalFilters.payers = this.selected.map((s) => s.itemName);
    this.hospitalFilters.hospitals = this.selected_hospital
    this.hospitalFilters.pageNum = 1;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    $('.ng-input').find('input:text').val('');
    this.getHospitals();
  }

  hospitalChangeHandler() {
    this.hospitalFilters.hospitals = this.selected_hospital.map(
      (s) => s.itemName
    );

    this.hospitalFilters.pageNum = 1;
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
  }
  async addHospital() {
    
   // await this.getHospitalList();
  }
  onItemSelect_hospital(item: any) {
    
    $('.ng-input').find('input:text').val('');
    this.HospitalNameChangeHandler();
    // this.hospitalChangeHandler();
  }
  OnItemDeSelect_hospital(item: any) {
    this.HospitalNameChangeHandler();
    // this.hospitalChangeHandler();
  }
  onSelectAll_hospital(items: any) {
   // $('.ng-input').find('input:text').val('');
    
    this.HospitalNameChangeHandler();
    // this.hospitalChangeHandler();
  }
  onDeSelectAll_hospital(items: any) {
    //this.getHospitalList();
   // this.hospitalChangeHandler();
    this.HospitalNameChangeHandler();
  }

  SelfPayHandler() {
    this.hospitalFilters.maxPrice = this.maxValue;
    this.hospitalFilters.minPrice = this.minValue;

    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
  }

  searchClickHandler(event: Event) {
    if(this.treatmentname==""){
      this.treatmentname="";
    }
   
    this.hospitalFilters.q = this.treatmentname;
    this.hospitalFilters.withinMiles = this.miles;
    console.log(this.locationname);
    if (this.locationname.indexOf(',') == -1) {
      if(this.locationname==""){
        this.locationname="";
      }
      this.hospitalFilters.zip = this.locationname;
    } else {
      this.hospitalFilters.lat = parseFloat(this.locationname.split(',')[0]);
      this.hospitalFilters.lng = parseFloat(this.locationname.split(',')[1]);
      this.hospitalFilters.zip?(this.hospitalFilters.zip=""):false;
    }

    let showAll=this.activatedRoute.snapshot.queryParamMap.get('showAll');
    if(showAll == null){
      this.hospitalFilters.showAll="false";
    }
console.log("query filters",this.hospitalFilters);
    this.router.navigate(['/search'], {
      queryParams: this.hospitalFilters,
    });
    this.getHospitals();
    // document.title =
    //   'prices for ' +
    //   this.treatmentname +
    //   ' nearby ' +
    //   this.locationname +
    //   ' | Price Transparency';
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  titleClick() {
    var popup = document.getElementById('myPopup');
    console.log('in title click');
    popup.style.visibility = 'visible';
    popup.classList.toggle('show_title_popup');
  }

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.ScrollService.setScrollTop();

    this.FormData = this.builder.group({
      treatmentname: new FormControl('', Validators.required),
      locationname: new FormControl('', Validators.required),
      
      miles: new FormControl('', Validators.required)
    })

    let zip = this.activatedRoute.snapshot.queryParamMap.get('zip');
    this.miles = parseInt(
      this.activatedRoute.snapshot.queryParamMap.get('withinMiles')
    );

    this.treatmentname = this.activatedRoute.snapshot.queryParamMap.get('q');
    let maxPrice = this.activatedRoute.snapshot.queryParamMap.get('maxPrice');

    let minPrice = this.activatedRoute.snapshot.queryParamMap.get('minPrice');

    let orderBy = this.activatedRoute.snapshot.queryParamMap.get('orderBy');
    let rating = this.activatedRoute.snapshot.queryParamMap.get('rating');
    let pageNum = +this.activatedRoute.snapshot.queryParamMap.get('pageNum');
    //let pageSize = +this.activatedRoute.snapshot.queryParamMap.get('pageSize');
    orderBy = orderBy != null ? orderBy : 'distance';

    let showAll = this.activatedRoute.snapshot.queryParamMap.get(
      'showAll'
    );
    
    this.radiobuttonvalue = showAll;
    if (showAll=="true"){
      this.valueChange = true;
    }
    else{
      this.valueChange = false
    }
    // this.valueChange = !Boolean(showAll);
    console.log("bool",this.valueChange,showAll);
    this.hospitalFilters = {
      q: this.treatmentname,
      withinMiles: this.miles,
      pageNum: pageNum != 0 ? pageNum : 1,
    //  pageSize: pageSize != 0 ? pageSize : 10,
      orderBy: orderBy,
      showAll: showAll,
    };

    let latitude = this.activatedRoute.snapshot.queryParamMap.get('lat');
    let longitude = this.activatedRoute.snapshot.queryParamMap.get('lng');

    if (zip == null && latitude!=null && longitude !=null) {
      this.hospitalFilters.lat = parseFloat(latitude)
    
      this.hospitalFilters.lng = parseFloat(longitude)
      this.locationname =
        this.hospitalFilters.lat + ',' + this.hospitalFilters.lng;
    } else if((zip != null && latitude==null && longitude ==null)) {
      this.hospitalFilters.zip = zip;
      this.locationname = zip;
    }
    else{
      
    }

    if (maxPrice != null && minPrice != null) {
      this.minValue = parseInt(minPrice);
      this.maxValue = parseInt(maxPrice);
      this.hospitalFilters.minPrice = this.minValue;
      this.hospitalFilters.maxPrice = this.maxValue;
    }

    if (rating != null) {
      this.starselected = parseInt(rating);
      this.hospitalFilters.rating = this.starselected;
    }
    this.dropdownSettings = {
      singleSelection: false,
    //  text: 'Select Payer',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      lazyLoading: true,
    };

    this.dropdownSettings_hospital = {
      singleSelection: false,
      text: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      lazyLoading: true,
    };

    let sortdropdown = this.activatedRoute.snapshot.queryParamMap.get(
      'orderBy'
    );
    if (sortdropdown != null) {
      this.selectedDropdown = sortdropdown;
      this.hospitalFilters.orderBy = sortdropdown;
    }

    

    let payers = this.activatedRoute.snapshot.queryParamMap.getAll('payers');
    
    if (payers.length != 0) {
      this.selected = payers
      
      
      this.hospitalFilters.payers = payers;
    }


    let hospitals = this.activatedRoute.snapshot.queryParamMap.getAll('hospitals');
    if (hospitals.length != 0) {
      this.selected_hospital = hospitals 
      this.hospitalFilters.hospitals = hospitals;
    }

    // await this.getPayers();
    
    this.getHospitals();
    //this.getHospitalList();

   // document.title ='Evaluprice';
    // if (this.treatmentname != undefined) {
    //   document.title =
    //     'prices for ' +
    //     this.treatmentname +
    //     ' nearby ' +
    //     this.locationname +
    //     ' | Price Transparency';
    // } else {
    //   document.title =
    //     'prices for nearby ' + this.locationname + ' | Price Transparency';
    // }

    let innerWidth = window.innerWidth;
    console.log('innerwidth', innerWidth);
    if (innerWidth <= 892) {
      
      document.getElementById('sort-title').classList.remove('sorttitle');
      document.getElementById('sort-bread').classList.add('sorttitle');
    }
  }
  ngAfterViewInit() {}
}
