import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Contactus } from '../models/contactus';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {
  
  constructor(private http: HttpClient) {
    super();
  }

  SendcontactUs(filters: Contactus): Observable<any>  {
    
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/json');

    if (filters) {
      headers = headers.set('Content-Type', 'application/json');
    }  
   // console.log("filters in contact service");
    return this.http.post(this.getBaseUrl()+`api/contactus`, filters, {
      headers
    })
  }

  SendcontactSupport(filters: Contactus): Observable<any>  {
    
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/json');

    if (filters) {
      headers = headers.set('Content-Type', 'application/json');
    }  
   // console.log("filters in contact service");
    return this.http.post(this.getBaseUrl()+`api/contactsupport`, filters, {
      headers
    })
  }

}
